import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSerializer, createUrlTreeFromSnapshot } from '@angular/router';

/**
 * Factory function that creates a redirect preserving guard.
 * This guard replaces a specific segment in the URL with another segment while preserving the rest of the URL.
 *
 * @param segmentToReplace - The segment in the URL that needs to be replaced (without the leading `/` or trailing '/').
 * @param segmentToReplaceWith - The segment that will replace the `segmentToReplace`.
 * @returns A function that acts as a redirect preserving guard.
 */
export const redirectPreservingGuardFactory =
	({ segmentToReplace, segmentToReplaceWith }: { segmentToReplace: string; segmentToReplaceWith: string }) =>
	(route: ActivatedRouteSnapshot) => {
		// /booking/table/direct?direct=true&email=pippo@gmail.com
		// diventa
		//  /book/direct?direct=true&email=pippo@gmail.com
		const router = inject(Router);
		const urlSerializer = inject(UrlSerializer);
		const qp = route.queryParams;
		const tree = createUrlTreeFromSnapshot(route, ['.']);
		const serialized = urlSerializer.serialize(tree);
		const newUrl = serialized.replace('/' + segmentToReplace, '/' + segmentToReplaceWith);
		router.navigate([newUrl], { replaceUrl: true, queryParams: qp });
		return false;
	};
