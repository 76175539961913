import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { IBooking, IBookingAdd } from '@scb-lib/models';
import { BOOKING_API_ADDRESS, BOOKING_API_ENDPOINTS } from '@scbweb-lib/const';
// import { scbWebFbFs } from 'app/firebase';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { doc, getDoc } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class BookingService {
	// scbWebFbFs = scbWebFbFs;
	constructor(
		private scbWebFbFs: Firestore,
		private http: HttpClient
	) {}

	async createBooking(body: IBookingAdd): Promise<IBooking> {
		const url = `${environment.functionsDomain}/${BOOKING_API_ADDRESS}/${BOOKING_API_ENDPOINTS.addBooking}`;
		// const options = {
		// 	method: 'POST',
		// 	url,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// 	body: JSON.stringify(body),
		// };

		// try {
		// 	const response = await fetch(url, options);
		// 	if (response.status !== 200) throw new Error(await response.text());
		// 	return await response.json();
		// } catch (error: unknown) {
		// 	console.error({ error });
		// 	if (isError(error)) {
		// 		throw new Error(error.message);
		// 	} else {
		// 		throw new Error(error as string);
		// 	}
		// }

		// FIXME http observable doesn't emit anything, value, nor error,
		// even if the call to the api is made and response is received(see chrome network)
		return firstValueFrom(this.http.post(url, body)) as Promise<IBooking>;
	}

	getBookingById(bookingId: string, storeId: string): Promise<IBooking> {
		return getDoc(doc(this.scbWebFbFs, `stores/${storeId}/bookings/${bookingId}`)).then((s) => s.data() as IBooking);
	}
}
